import React from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { FaMap,FaPhone,FaEnvelope,FaUser,FaChurch,FaAddressCard,FaUsersBetweenLines } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import headsignup from './../img/cat-222.jpg'
const Signup = () => {
    const { REACT_APP_RcSiteKey } = process.env;
    const [btnload, setBtnload] = useState(false);
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [fullname, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [gender, setGender] = useState("");
    const [org, setOrgName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const captchaRef = useRef()
    const navigate = useNavigate();
    
     const baseUrl = process.env.REACT_APP_BaseUrl;
    // const baseUrl = "https://www.serve.maverick-minds.com";
    const [currentDate, setCurrentDate] = useState(new Date());
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    }
    const sendEmail = async () => {
        
        if(recaptchaValue==''){
            alert('ReCaptcha Should not be empty');
            return false;
        }
        setBtnload(true)
      let dataSend = {
        firstname: firstname,
        lastname: lastname,
        fullname: fullname,
        email: email,
        phone: phone,
        zip: zip,
        address: address,
        gender: gender,
        org: org,
        message: message,
        dt: formatDate(currentDate),
      };
      
      const res = await fetch(`${baseUrl}/email/sendSignupEmail`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        // HANDLING ERRORS
        .then((res) => {
          console.log(res);
          setBtnload(false)
          if (res.status > 199 && res.status < 300) {
            navigate('/thank-you');
          }
        });
    };
    const onChange = value => {
        setRecaptchaValue(value);
      }
    
  return (
<>
<div className="container-xxl py-4">
        <div className="container">
        
        
        <div className="LoginPageContainer">
    <div className="LoginPageInnerContainer">
        <div className="ImageContianer">
        <div className="row">
            <div className="text-center offset-md-2 col-lg-8 col-md-12 wow fadeInUp">
                <h6 className="section-title bg-white text-center text-primary px-3">Fill the form</h6>
                <header className="header"><h2 className="mb-5">Sign Up</h2></header>
            </div>
        </div>
           
            <img className="GroupImage" src={headsignup} alt="" />
        </div>
        <div className="LoginFormContainer">
            <div className="LoginFormInnerContainer">
            
                
                <header className="subHeader">Welcome to <b>Maverick Mind!</b> Please Enter your Details</header>

                
                <div className="row g-3">
                <div className='col-md-3'>
                <div className="inputContainer">
                    <label className="label" for="emailAddress">
                    <FaUsersBetweenLines className="text-secondary me-1 labelIcon" /><span> <span className='text-danger'>*</span>
                            </span>
                            </label>
                            <select name="gender" className="form-control input" onChange={(e) => setGender(e.target.value)} required >
                                <option value="male">Mr.</option>
                                <option value="female">Mrs.</option>
                                
                            </select>
                </div>
                </div>
                <div className="col-md-9">
                            <div className="inputContainer">
                                <label className="label" for="emailAddress"><FaUser className="text-secondary me-1 labelIcon" />
                                <span>Full Name <span className='text-danger'>*</span>
                                        </span>
                                        </label>
                                <input type="text" onChange={(e) => setFullName(e.target.value)} className="input" id="emailAddress" placeholder="Enter your Full Name" />
                        </div>
                        </div>
                   
                </div>
                    <div className="row">
                    <div className="col-md-12">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <img src="https://i.imgur.com/Hn13wvm.png" className="labelIcon" /><span>Email Address <span className='text-danger'>*</span>
                                    </span>
                                    </label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="input" id="emailAddress" placeholder="Enter your Email Address" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <FaPhone className="text-secondary me-1 labelIcon" /><span>Phone Number <span className='text-danger'>*</span>
                                    </span>
                                    </label>
                            <input type="number" onChange={(e) => setPhone(e.target.value)} className="input" id="emailAddress" placeholder="Enter your Phone No" />
                        </div>
                    </div>
                </div>
                
                <div className="row">
                  <div className="col-md-3">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <FaMap className="text-secondary me-1 labelIcon" /><span>Zip Code 
                                    </span>
                                    </label>
                                    <input type="text" onChange={(e) => setZip(e.target.value)} className="input" id="emailAddress" placeholder="Enter your Zip Code" />
                        </div>
                    </div> 
                    <div className="col-md-9">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <FaAddressCard className="text-secondary me-1 labelIcon" /><span>Address 
                                    </span>
                                    </label>
                                    <input type="text" onChange={(e) => setAddress(e.target.value)} className="input" id="emailAddress" placeholder="Enter your Address" />
                        </div>
                    </div> 
                </div>
                <div className="row">
                
                  <div className="col-md-12">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <FaChurch className="text-secondary me-1 labelIcon" /><span>Enter Your School Organization <span className='text-danger'>*</span>
                                    </span>
                                    </label>
                                    <input type="text" className="input" id="emailAddress" placeholder="Enter your School Organization" />
                        </div>
                    </div> 
                   
                </div> 
                <div className="row">
                  <div className="col-md-12">
                        <div className="inputContainer">
                            <label className="label" for="emailAddress">
                            <FaEnvelope className="text-secondary me-1 labelIcon" /><span>Enter Message <span className='text-danger'>*</span>
                                    </span>
                                    </label>
                                    <textarea className="form-control input" onChange={(e) => setMessage(e.target.value)} placeholder="Leave a message here" id="message" style={{height: "150px"}} required></textarea>
                        </div>
                    </div> 
                   
                </div>  
                <div className="row">
                <div className="col-12 col-lg-6">
                              <ReCAPTCHA
                                    sitekey={REACT_APP_RcSiteKey}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-12 col-lg-6 text-right">
                            {
                                btnload?
                                <button class="btn btn-primary" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm me-2 cs-btn" role="status" aria-hidden="true"></span>
                                    Loading...
                                    </button>
                                : <button className="btn btn-primary  py-3 me-4" type="submit" onClick={() => sendEmail()}>Submit Now</button>
                                }
                            
                           
                           </div>
                  </div>
                   
                
            </div>
        </div>
    </div>
</div>
          
            
        </div>
    </div>
<Footer />
</>
  );
}

export default Signup;